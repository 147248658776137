require('./bootstrap');
window.Picker = require('vanilla-picker');
window.Pikaday = require('pikaday');
require('trix');

// Burger menus
document.addEventListener('DOMContentLoaded', function() {
	Livewire.on('darkModeNavToggle', value => {
        value = value[0];
		const toggleDarkSetting = document.getElementById('darkMode');
     
        if(toggleDarkSetting){
            toggleDarkSetting.checked = value;
        }

		var element = document.getElementsByTagName("HTML")[0];
        element.classList.toggle("dark"); 
	})

    Livewire.on('darkModeSettingToggle', value => {
        value = value[0];
		const toggleNavDarkMode = document.getElementById('navDarkMode');	
        
        if(toggleNavDarkMode){
            toggleNavDarkMode.checked = value;
        }

		var element = document.getElementsByTagName("HTML")[0];
        element.classList.toggle("dark"); 
	})

});


